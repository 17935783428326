import { create } from 'zustand';
import { LOGIN_STEP } from '../types';
import { DiscoveredOrganization } from '@stytch/vanilla-js';

interface LoginState {
  loginStep: LOGIN_STEP;
  emailAddress?: string;
  organizationSlug?: string;
  discoveredOrganizations?: Array<DiscoveredOrganization>;
}

interface LoginActions {
  setLoginStep: (loginStep: LOGIN_STEP) => void;
  setLoginEmail: (email: string) => void;
  setOrganizationSlug: (organizationSlug: string) => void;
  setLoginDetails: (email: string, organizationSlug: string) => void;
  setDiscoveredOrganizations: (
    discoveredOrganizations: Array<DiscoveredOrganization>
  ) => void;
  resetLoginDetails: () => void;
}

const initialState: LoginState = {
  loginStep: LOGIN_STEP.MAIN,
};

type LoginStore = LoginState & {
  actions: LoginActions;
};

const loginStore = create<LoginStore>((set) => ({
  ...initialState,
  actions: {
    setLoginStep: (loginStep) => set((state) => ({ ...state, loginStep })),
    setLoginEmail: (emailAddress) =>
      set((state) => ({ ...state, emailAddress })),
    setOrganizationSlug: (organizationSlug) =>
      set((state) => ({ ...state, organizationSlug })),
    setLoginDetails: (emailAddress, organizationSlug) =>
      set((state) => ({ ...state, emailAddress, organizationSlug })),
    setDiscoveredOrganizations: (discoveredOrganizations) =>
      set((state) => ({ ...state, discoveredOrganizations })),
    resetLoginDetails: () => set((state) => initialState),
  },
}));

export const useLoginStep = () => loginStore((state) => state.loginStep);
export const useLoginDetails = () =>
  loginStore((state) => ({
    emailAddress: state.emailAddress,
    organizationSlug: state.organizationSlug,
  }));
export const useDiscoveredOrganizations = () =>
  loginStore((state) => state.discoveredOrganizations);
export const useLoginStore = loginStore;
export const useLoginActions = () => loginStore((state) => state.actions);
export const setLoginStep = loginStore.getState().actions.setLoginStep;
export const setLoginEmail = loginStore.getState().actions.setLoginEmail;
export const resetLoginDetails =
  loginStore.getState().actions.resetLoginDetails;
export const setOrganizationSlug =
  loginStore.getState().actions.setOrganizationSlug;
export const setLoginDetails = loginStore.getState().actions.setLoginDetails;
export const organizationSlug = loginStore.getState().organizationSlug;

import {
  getNotification,
  useNotificationAction,
} from '@/shared/states/notification';
import { useRef, useEffect, FC } from 'react';
import { Card, CardBody, CardFooter, CardHeader } from '@nextui-org/react';
import { motion } from 'framer-motion';
import { cn } from '@/shared/utils/classname-merger';
import { X, CircleCheck, TriangleAlert, Info } from 'lucide-react';

const Notification: FC<{ id: string }> = ({ id }) => {
  const mountRef = useRef(false);

  // const {
  //   open,
  //   message,
  //   autoHideDuration = 4000,
  //   type = 'success',
  //   title,
  //   actionButton,
  // } = getNotification(id);
  const notificationData = getNotification(id);
  const { hideNotification } = useNotificationAction();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const wasMounted = mountRef.current;
    if (!wasMounted) {
      timer = setTimeout(function () {
        hideNotification(id);
      }, notificationData?.autoHideDuration ?? 4000);
      mountRef.current = true;
    }

    return () => {
      if (timer && wasMounted) {
        clearTimeout(timer);
      }
    };
  }, [hideNotification, id, notificationData]);

  const getNotificationIcon = (
    data: string = notificationData?.type ?? 'success'
  ) => {
    switch (data) {
      case 'success':
        return <CircleCheck color="#12A150" />;
      case 'error':
        return <TriangleAlert color="#C20E4D" />;
      case 'warn':
        return <TriangleAlert color="#C4841D" />;
      case 'info':
        return <Info color="#005BC4" />;
      default:
        return <></>;
    }
  };

  const typeStyles = {
    success: {
      border: 'border-[#1FCB6A]',
      headerText: 'text-[#095028]',
      background: 'bg-[#DAEBE2]',
      bodyText: 'text-[#095028]',
    },
    error: {
      border: 'border-[#F31260]',
      headerText: 'text-[#C20E4D]',
      background: 'bg-[#efd9e1]',
      bodyText: 'text-[#610726]',
    },
    info: {
      border: 'border-[#006FEE]',
      headerText: 'text-[#005BC4]',
      background: 'bg-[#D7E2EF]',
      bodyText: 'text-[#002E62]',
    },
    warn: {
      border: 'border-[#F5A524]',
      headerText: 'text-[#C4841D]',
      background: 'bg-[#f0e8db] ',
      bodyText: 'text-[#62420E]',
    },
    default: {
      border: 'border-[#F5A524]',
      headerText: 'text-[#C4841D]',
      background: 'bg-[#f0e8db] ',
      bodyText: 'text-[#62420E]',
    },
  };

  if (notificationData === undefined) {
    return null;
  }
  if (notificationData && !notificationData.open) {
    return null;
  }
  const styles = typeStyles[notificationData?.type ?? 'success'];

  return (
    <motion.div
      initial={{ x: '100%' }}
      animate={{ x: 0 }}
      exit={{ x: '100%' }}
      transition={{ duration: 0.2 }}
      onClick={() => {
        hideNotification(id);
      }}
    >
      <Card
        className={cn(
          'w-[471px]  rounded-lg outline-none border-[1px] hover:cursor-pointer relative pb-[5px]',
          styles.border,
          styles.background
        )}
      >
        <X
          size={14}
          onClick={() => hideNotification(id)}
          className="absolute top-[15px] right-[15px]"
        />
        {notificationData?.title ? (
          <CardHeader
            className={cn(styles.headerText, 'pb-0 flex justify-between ')}
          >
            <div className="flex flex-row gap-x-[8px]">
              {getNotificationIcon(notificationData?.type ?? 'success')}
              <p className={cn(styles.headerText, 'font-medium text-[16px]')}>
                {notificationData?.title}
              </p>
            </div>
          </CardHeader>
        ) : null}
        {notificationData?.message ? (
          <CardBody className={cn(styles.bodyText)}>
            <p className={cn(styles.bodyText, 'text-[14px]')}>
              {notificationData?.message}
            </p>
          </CardBody>
        ) : null}
        {notificationData?.actionButton ? (
          <CardFooter>
            <div>{notificationData?.actionButton}</div>
          </CardFooter>
        ) : null}
      </Card>
    </motion.div>
  );
};

export default Notification;

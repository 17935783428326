export interface MagicLinkRequest {
  email_id: string;
}

export interface MagicLinkResponse {
  success: boolean;
  //FIXME
  payload: any;
}

export interface AuthenticateTokenRequest {
  token: string;
}

export interface ValidateSessionTokenRequest {
  session_token: string;
}

export interface TokenResponse {
  success: boolean;
  session_data: SessionData;
  payload: any;
}

export interface SessionData {
  status_code: number;
  request_id: string;
  session: Session;
  session_token: string;
  session_jwt: string;
  user: User;
}

export interface Session {
  session_id: string;
  user_id: string;
  authentication_factors: AuthenticationFactor[];
  started_at: string;
  last_accessed_at: string;
  expires_at: string;
  attributes: Attributes;
  custom_claims: CustomClaims;
}

export interface AuthenticationFactor {
  type: string;
  delivery_method: string;
  last_authenticated_at: string;
  created_at: string;
  updated_at: string;
  email_factor: EmailFactor;
  phone_number_factor: any;
  google_oauth_factor: any;
  microsoft_oauth_factor: any;
  apple_oauth_factor: any;
  webauthn_factor: any;
  authenticator_app_factor: any;
  github_oauth_factor: any;
  recovery_code_factor: any;
  facebook_oauth_factor: any;
  crypto_wallet_factor: any;
  amazon_oauth_factor: any;
  bitbucket_oauth_factor: any;
  coinbase_oauth_factor: any;
  discord_oauth_factor: any;
  figma_oauth_factor: any;
  git_lab_oauth_factor: any;
  instagram_oauth_factor: any;
  linked_in_oauth_factor: any;
  shopify_oauth_factor: any;
  slack_oauth_factor: any;
  snapchat_oauth_factor: any;
  spotify_oauth_factor: any;
  steam_oauth_factor: any;
  tik_tok_oauth_factor: any;
  twitch_oauth_factor: any;
  twitter_oauth_factor: any;
  embeddable_magic_link_factor: any;
  biometric_factor: any;
  saml_sso_factor: any;
  oidc_sso_factor: any;
  salesforce_oauth_factor: any;
  yahoo_oauth_factor: any;
}

export interface EmailFactor {
  email_id: string;
  email_address: string;
}

export interface Attributes {
  ip_address: string;
  user_agent: string;
}

export interface CustomClaims {}

export interface User {
  user_id: string;
  emails: Email[];
  status: string;
  phone_numbers: any[];
  webauthn_registrations: any[];
  providers: any[];
  totps: any[];
  crypto_wallets: any[];
  biometric_registrations: any[];
  name: Name;
  created_at: string;
  password: any;
  trusted_metadata: TrustedMetadata;
  untrusted_metadata: UntrustedMetadata;
}

export interface Email {
  email_id: string;
  email: string;
  verified: boolean;
}

export interface Name {
  first_name: string;
  middle_name: string;
  last_name: string;
}

export interface TrustedMetadata {}

export interface UntrustedMetadata {}

export enum LOGIN_STEP {
  MAIN = 'main',
  EMAIL_SUCCESS = 'email_success',
  SELECT_ORG = 'select_org',
  NEW_ORG = 'new_org',
  ERROR = 'error',
  CONTACT_ADMIN = 'contact_admin',
  SSO = 'sso',
  ACCESS_RESTRICTED = 'access_restricted',
}

export type ListOfOrganizationByEmail = {
  success: boolean;
  message: string;
  payload: Array<{
    organization_name: string;
    connection_id: Array<string>;
  }>;
};

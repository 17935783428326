import { get } from '@/infra/rest';
import {
  AgentSessionStatus,
  AgentSessionStepType,
  MultiVendorAgentSession,
} from '@/modules/sessions/types';
import {
  getAgentData,
  getAgentSessionData,
  getAgentStateActions,
} from '../states';
import {
  AGENT_TYPES,
  AgentData,
  AgentSpecificReviewResponseType,
  ConfidenceTypes,
  MultiVendorAssessmentTypes,
  ReviewResponseData,
  ReviewSourceTypes,
  MultiVendorAssessmentTableRow,
  ComplianceStatus,
} from '../types/index.ts';
import { v4 as uuid } from 'uuid';
import { getRenderType } from '../utils/get-render-type';
import { getFieldTitle } from '../utils/getFieldTitle';
import { VendorRiskResponseItem, VendorRiskResponseMappedBySection, VendorRiskSectionEnum } from '../types/vendorAssessment.ts';
import { VendorRiskResponseItemForSecurityAssessment } from '../types/vendorSecurityAssessment.ts';
import { VendorRiskResponseItemForVendorOverview } from '../types/vendorOverviewSection.ts';

export const processFileForVendorRiskReview = async (agentId: string) => {
  const currentData = getAgentData<AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT, MultiVendorAssessmentTypes.VENDOR_ASSESSMENT>(agentId);
  
  const vendorIntakeFormStep = currentData?.stepData.find(
    (step) => step.type === AgentSessionStepType.MULTI_VENDOR_PROFILE
  );
  const editResponseStep = currentData?.stepData.find(
    (step) => step.type === AgentSessionStepType.EDIT_RESPONSE
  );

  if (!editResponseStep?.data.url) {
    throw new Error('Failed to fetch vendor assessment response');
  }

  const vendorJson = await get<VendorRiskResponseItem[]>({
    url: editResponseStep.data.url,
    isAuthRequired: false,
  });

  let result: VendorRiskResponseMappedBySection = {
    ai_security_assessment: [],
    vendor_overview: [],
    vendor_supply_chain: [],
    zania_security_assessment: [],
  };

  for (const key of Object.keys(result)) {
    result = {
      ...result,
      [key]: vendorJson.filter(
        (vendor) => vendor.section === (key as VendorRiskSectionEnum)
      ),
    };
  }

  if (vendorIntakeFormStep) {
    const complianceInfoIndex = result[VendorRiskSectionEnum.vendor_overview].findIndex(val => val.key === 'compliance');
    const complianceItem = result[VendorRiskSectionEnum.vendor_overview][complianceInfoIndex] as VendorRiskResponseItemForVendorOverview<'compliance'>;
    if (complianceInfoIndex > -1) {
      complianceItem.customer_added_certifications = [
        ...(complianceItem.customer_added_certifications ?? []),
        ...(vendorIntakeFormStep.data.vendor_intake_form?.customer_added_certifications ?? []),
      ];
    }
  }

  return result;
};

export const generateFinalVendorReviewResponseData = (agentId: string) => {
  const agentData = getAgentData<
    AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT,
    MultiVendorAssessmentTypes.VENDOR_ASSESSMENT
  >(agentId);

  if (!agentData) {
    throw new Error('An error occurred');
  }

  const { mainData } = agentData;
  const { reviewResponseData } = mainData;

  if (!reviewResponseData) {
    throw new Error('An error occurred');
  }

  const approvedIds = mainData.approvedIds;
  const editedIds = mainData.editedIds;
  const result: VendorRiskResponseItemForSecurityAssessment[] = [];

  reviewResponseData.forEach((item, id) => {
    const newValue: VendorRiskResponseItemForSecurityAssessment['value'] = {
      ...item.find(item => item.key === 'value')?.value,
      sources: item.find(item => (item.key as any) === 'sources')?.value,
      compliance_status: item.find(item => (item.key as any) === 'compliance_status')?.value,
      observations: item.find(item => (item.key as any) === 'observations')?.value,
    }
    const risk: VendorRiskResponseItemForSecurityAssessment = {
      approved: approvedIds.includes(id),
      edited: editedIds.includes(id),
      id,
      value: newValue,
      section: (item.find((item) => item.key === 'section')?.value as (VendorRiskSectionEnum.ai_security_assessment) || VendorRiskSectionEnum.zania_security_assessment),
      key: item.find((item) => item.key === 'key')?.value as string,
      question: item.find((item) => item.key === 'question')?.value as string,
    };
    result.push(risk);
  });

  return result;
};

export const processVendorRiskReviewData = async (agentId: string, riskReviewData: VendorRiskResponseItemForSecurityAssessment[]) => {
  const keysToOmit = [
    'id',
    'subcategory',
    'function',
    'category',
    'approved',
    'edited',
    'questions',
    'impact',
    'likelihood',
    'impact_label',
    'likelihood_label',
    'control_id',
    'zania_control_id',
    'justification',
    'section',
    'key',
    'confidence',
    'question',
    'value'
  ];

  const ids: string[] = [];
  const tableRows: MultiVendorAssessmentTableRow[] = [];
  const approvedIds: string[] = [];
  const editedIds: string[] = [];
  const reviewResponse = new Map<
    string,
    ReviewResponseData<
      keyof AgentSpecificReviewResponseType<AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT, MultiVendorAssessmentTypes.VENDOR_ASSESSMENT>
    >[]
  >(
    riskReviewData.map((risk) => {
      const id = uuid();
      ids.push(id);
      const tableRow: MultiVendorAssessmentTableRow = {
        id: id,
        question: risk.question ?? '',
        compliant: risk.value.compliance_status as ComplianceStatus,
        confidence: risk.value.confidence ?? '',
        sources: risk.value.sources ?? [],
        status: risk.approved ? 'approved' : risk.edited ? 'edited' : 'none',
      };

      tableRows.push(tableRow);

      if (risk.approved) {
        approvedIds.push(id);
      }

      if (risk.edited) {
        editedIds.push(id);
      }

      const orderedKeys = ['compliance_status', 'observations', 'sources'];

      const orderedData = orderedKeys.map((key) => ({
        type: getRenderType(key),
        value: risk.value[key as keyof typeof risk.value],
        key: key as keyof AgentSpecificReviewResponseType<
          AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT,
          MultiVendorAssessmentTypes.VENDOR_ASSESSMENT
        >,
        title: getFieldTitle(key),
        shouldRender: !keysToOmit.includes(key),
      }));

      Object.entries(risk).forEach(([key, value]) => {
        if (!orderedKeys.includes(key)) {
          const data: ReviewResponseData<
            keyof AgentSpecificReviewResponseType<AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT, MultiVendorAssessmentTypes.VENDOR_ASSESSMENT>
          > = {
            type: getRenderType(key),
            value: value as unknown,
            key: key as keyof AgentSpecificReviewResponseType<AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT, MultiVendorAssessmentTypes.VENDOR_ASSESSMENT>,
            title: getFieldTitle(key),
            shouldRender: !keysToOmit.includes(key),
            isDisabled: (key === 'question') ? true : false,
          };
          orderedData.push(data);
        }
      });

      return [id, orderedData];
    })
  );

  const currentData = getAgentData<
    AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT,
    MultiVendorAssessmentTypes.VENDOR_ASSESSMENT
  >(agentId);

  if (!currentData) {
    throw new Error('An error occurred');
  }

  const stepData = currentData.stepData.map((step) => {
    if (step.type === AgentSessionStepType.MULTI_FILE_VENDOR_ASSESSMENT) {
      step.status = AgentSessionStatus.COMPLETE;
    }
    if (step.type === AgentSessionStepType.EDIT_RESPONSE) {
      step.status = AgentSessionStatus.INPUT_NEEDED;
    }
    return step;
  });

  const session = getAgentSessionData(agentId) as MultiVendorAgentSession;

  const agentData: Partial<
    AgentData<AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT, MultiVendorAssessmentTypes.VENDOR_ASSESSMENT
    >
  > = {
    agentType: AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT,
    sessionData: session as MultiVendorAgentSession,
    stepData,
    mainData: {
      ...currentData.mainData,
      reviewResponseData: reviewResponse,
      reviewResponseIds: ids,
      tableRows,
      searchFor: ['question'],
      searchTerm: '',
      approvedIds,
      editedIds,
    },
  };

  const { updateAgentData } = getAgentStateActions();

  updateAgentData<
    AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT,
    MultiVendorAssessmentTypes.VENDOR_ASSESSMENT
  >(agentId, agentData);
};
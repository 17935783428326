interface RequestConfig {
  baseUrl: string;
  socketUrl: string;
  socketPath: string;
  slackUrl: string;
}

let requestConfig: RequestConfig = {
  baseUrl: 'https://api.zania.app/frontend/dev',
  socketUrl: 'wss://api.zania.app',
  slackUrl: 'https://api.zania.app/slack-bot-staging/v0/slack/install',
  socketPath: '/internal/dev/socket/',
};

switch (import.meta.env.MODE) {
  case 'demo': {
    requestConfig = {
      baseUrl: 'https://api.zania.app/frontend/staging',
      socketUrl: 'wss://api.zania.app',
      slackUrl: 'https://api.zania.app/slack-bot-staging/v0/slack/install',
      socketPath: '/internal/staging/socket/',
    };
    break;
  }
  case 'production': {
    requestConfig = {
      baseUrl: 'https://api.zania.app/frontend',
      socketUrl: 'wss://api.zania.app',
      slackUrl: 'https://api.zania.app/slack-bot/v0/slack/install',
      socketPath: '/internal/socket/',
    };
    break;
  }
  case 'staging': {
    requestConfig = {
      baseUrl: 'https://api.zania.app/frontend/staging',
      socketUrl: 'wss://api.zania.app',
      slackUrl: 'https://api.zania.app/slack-bot-staging/v0/slack/install',
      socketPath: '/internal/staging/socket/',
    };
    break;
  }
  case 'development': {
    requestConfig = {
      baseUrl: 'https://api.zania.app/frontend/dev',
      socketUrl: 'wss://api.zania.app',
      slackUrl: 'https://api.zania.app/slack-bot-staging/v0/slack/install',
      socketPath: '/internal/dev/socket/',
    };
    break;
  }
  case 'localhost': {
    requestConfig = {
      baseUrl: 'http://localhost:8000',
      socketUrl: 'ws://localhost:8000',
      slackUrl: 'http://localhost:8000/slack-bot-staging/v0/slack/install',
      socketPath: '',
    };
    break;
  }
}

export default requestConfig;

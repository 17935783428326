export const getFileNameFromUrl = (url: string) => {
  const urlObj = new URL(url);

  const filename = urlObj.pathname.split('/').pop();

  if (!filename) {
    throw new Error('Filename not found in URL');
  }

  return decodeURIComponent(filename);
};

import { VendorRiskResponseItem, VendorRiskResponseMappedBySection } from '@/modules/agent/types/vendorAssessment';
import { VendorRiskResponseItemForVendorOverview } from '@/modules/agent/types/vendorOverviewSection';
import { VendorRiskResponseItemForVendorSupplyChain } from '@/modules/agent/types/vendorSupplyChain';
import { create } from 'zustand';

export type VendorPageType = 'OVERVIEW' | 'SUPPLY_CHAIN' | 'ASSESSMENT';

interface IVendorRiskAssessmentStore {
  selectedTab: VendorPageType;
  changesMade: number;
  isSaving: boolean;
  combinedVendorRiskData: VendorRiskResponseMappedBySection | null;
  vendorOverview: VendorRiskResponseItemForVendorOverview[];
  vendorSupplyChainData: VendorRiskResponseItemForVendorSupplyChain[];
  vendorAssessmentData: VendorRiskResponseItem[];
  setSelectedTab: (selectedTab: VendorPageType) => void;
  setCombinedVendorRiskData: (
    combinedVendorRiskData: VendorRiskResponseMappedBySection
  ) => void;
  setVendorOverview: (
    vendorOverview: VendorRiskResponseItemForVendorOverview[],
    dontIncrementChangesMade?: boolean
  ) => void;
  setVendorSupplyChain: (
    vendorSupplyChainData: VendorRiskResponseItemForVendorSupplyChain[],
    dontIncrementChangesMade?: boolean
  ) => void;
  setVendorAssessment: (
    vendorAssessmentData: VendorRiskResponseItem[],
    dontIncrementChangesMade?: boolean
  ) => void;
  setIsSaving: (newIsSaving: boolean) => void;
  reset: () => void;
}

export const useVendorRiskAssessmentStore = create<IVendorRiskAssessmentStore>(
  (set, get) => ({
    selectedTab: 'OVERVIEW',
    changesMade: 0,
    isSaving: false,
    combinedVendorRiskData: null,
    vendorOverview: [],
    vendorSupplyChainData: [],
    vendorAssessmentData: [],
    setSelectedTab: (selectedTab: VendorPageType) => set({ selectedTab }),
    setCombinedVendorRiskData: (combinedVendorRiskData: VendorRiskResponseMappedBySection) =>
      set({ combinedVendorRiskData }),
    setVendorOverview: (
      vendorOverview: VendorRiskResponseItemForVendorOverview[],
      dontIncrementChangesMade?: boolean
    ) => {
      set({
        vendorOverview,
        changesMade: get().changesMade + (dontIncrementChangesMade ? 0 : 1),
      });
    },
    setVendorSupplyChain: (
      vendorSupplyChainData: VendorRiskResponseItemForVendorSupplyChain[],
      dontIncrementChangesMade?: boolean
    ) => {
      set({
        vendorSupplyChainData,
        changesMade: get().changesMade + (dontIncrementChangesMade ? 0 : 1),
      });
    },
    setVendorAssessment: (
      vendorAssessmentData: VendorRiskResponseItem[],
      dontIncrementChangesMade?: boolean
    ) => {
      set({
        vendorAssessmentData,
        changesMade: get().changesMade + (dontIncrementChangesMade ? 0 : 1),
      });
    },
    setIsSaving: (newIsSaving: boolean) => set({ isSaving: newIsSaving }),
    reset: () => {
      set({
        selectedTab: 'OVERVIEW',
        changesMade: 0,
        isSaving: false,
        combinedVendorRiskData: null,
        vendorOverview: [],
        vendorSupplyChainData: [],
        vendorAssessmentData: [],
      });
    },
  })
);


/* eslint-disable @typescript-eslint/unbound-method */
import { NavigateFunction } from 'react-router-dom';

import {
  AGENT_TYPES,
  AgentData,
  AgentSourceFile,
  AgentSpecificReviewResponseType,
  ConfidenceTypes,
  ComplianceStatus,
  GapAssessmentTypes,
  ReviewResponseData,
  ReviewSourceTypes,
  DoraGapReviewResponseTableRow,
} from '../types/index.ts';
import { userStateSelector } from '@/shared/states/user';
import {
  createAgentSession,
  getAgentSession,
  updateAgentSessionStep,
} from '@/modules/sessions/requests';
import {
  AgentSessionStatus,
  AgentSessionStep,
  AgentSessionStepType,
  AgentSessionType,
  CommonRagCreateRequest,
  DoraAgentSession,
} from '@/modules/sessions/types';
import { getAgentData, getAgentStateActions } from '../states';
import { getRAGExcelFromJSON } from '../requests/';
import { getSessionJWT } from '@/infra/stytch';
import { handleSocketResponse } from '../utils/socket-response';
import { getResponseTypes } from '../utils/get-response-type';
import { DoraReviewResponse } from '../types/risk-and-gap.ts';
import { getSignedUrl } from '@/shared/requests/get-signed-url';
import { uploadFileReq } from '@/shared/requests/upload-file';
import { get } from '@/infra/rest';
import { v4 as uuid } from 'uuid';
import { getRenderType } from '../utils/get-render-type';
import { addNotification } from '@/shared/states/notification';
import { AiResponseType } from '@/shared/types/user';
import { emitRagCreate } from '../requests';
import { getFieldTitle } from '../utils/getFieldTitle';
import { AutoSaveFunctionArgs } from '../utils/autosave';
import { ROUTES } from '@/shared/constants/routes';
import { AGENT_ROUTES } from '@/shared/constants/routes';

interface ProcessFileForDoraGapAssessmentArgs {
  name: string;
  sourceFiles: AgentSourceFile[];
  navigate: NavigateFunction;
}

export const processFileForDoraGapAssessment = async ({
  name,
  sourceFiles,
  navigate,
}: ProcessFileForDoraGapAssessmentArgs) => {
  const agentSessionType = AgentSessionType.DORA_GAP;
  const aiResponseType = userStateSelector.getState().aiResponseType;
  const source_urls = sourceFiles.map((file) => file.url);

  const { response_mode, response_quality } = getResponseTypes(aiResponseType);

  const gapAssessmentRequest: CommonRagCreateRequest = {
    doc_type: 'json',
    response_quality,
    source_urls,
  };

  const {
    data: { session, steps = [] },
  } = await createAgentSession({
    name: name ?? 'Dora Gap Assessment',
    type: agentSessionType,
    [agentSessionType]: gapAssessmentRequest,
  });

  if (!session || !session.id) {
    throw new Error('An error occurred');
  }

  const agentType = AGENT_TYPES.GAP_ASSESSMENT;
  const agentSubType = GapAssessmentTypes.DORA;
  const statusInProgressSessionStep = AgentSessionStepType.DORA_ASSESSMENT;
  const task = 'dora_assessment';
  const statusCompleteSessionSteps = [
    AgentSessionStepType.LOAD_TEMPLATE,
    AgentSessionStepType.EXTRACT_CONTROLS,
  ];

  const stepData = steps.reduce((acc, step) => {
    if (statusCompleteSessionSteps.includes(step.type)) {
      step.status = AgentSessionStatus.COMPLETE;
    }

    if (statusInProgressSessionStep === step.type) {
      step.status = AgentSessionStatus.IN_PROGRESS;
    }

    acc.push(step);
    return acc;
  }, [] as AgentSessionStep[]);

  const agentData: AgentData<typeof agentType, typeof agentSubType> = {
    agentType: agentType,
    sessionData: session as DoraAgentSession,
    stepData,
    subType: agentSubType,
    responseQuality: aiResponseType,
    mainData: {
      sourceFilesUrls: new Map(
        sourceFiles.map((file) => [file.fileName, file])
      ),
      approvedIds: [],
      editedIds: [],
    },
  };

  const agent_session_step_id =
    steps.find((step) => step.type === statusInProgressSessionStep)?.id ??
    steps.find((step) => step.type === AgentSessionStepType.EDIT_RESPONSE)
      ?.id ??
    '';

  const { setAgentData } = getAgentStateActions();
  setAgentData(session.id, agentData);

  await emitRagCreate(
    {
      agent_session_id: session.id,
      agent_session_step_id,
      token: await getSessionJWT(),
      rag_input: gapAssessmentRequest,
      response_mode,
      task: task,
    },
    (response: any) => {
      handleSocketResponse(response, navigate);
    }
  );
  navigate({
    pathname: `/agent/${session.id}/`,
  });
};

interface FinalDoraReviewResponse extends DoraReviewResponse {
  approved: boolean;
  edited: boolean;
  id: string;
}

export const generateFinalGapJSON = (
  agentId: string
): FinalDoraReviewResponse[] => {
  const agentData = getAgentData<
    AGENT_TYPES.GAP_ASSESSMENT,
    GapAssessmentTypes.DORA
  >(agentId);

  if (!agentData) {
    throw new Error('An error occurred');
  }

  const { mainData } = agentData;
  const { reviewResponseData } = mainData;

  if (!reviewResponseData) {
    throw new Error('An error occurred');
  }

  const approvedIds = mainData.approvedIds;
  const editedIds = mainData.editedIds;
  const finalGapJson: FinalDoraReviewResponse[] = [];

  reviewResponseData.forEach((item, id) => {
    const getValue = <T>(key: string, defaultValue: T): T => {
      return (
        (item.find((item: any) => item.key === key)?.value as T) ?? defaultValue
      );
    };

    const finalReviewResponseItem: FinalDoraReviewResponse = {
      id: id.toString(),
      approved: approvedIds.includes(id),
      edited: editedIds.includes(id),
      control_id: getValue<string>('control_id', ''),
      zania_control_id: getValue<string>('zania_control_id', ''),
      chapter: getValue<string>('chapter', ''),
      article: getValue<string>('article', ''),
      requirement: getValue<string>('requirement', ''),
      confidence: getValue<ConfidenceTypes>(
        'confidence',
        {} as ConfidenceTypes
      ),
      questions: getValue<string[]>('questions', []),
      observations: getValue<string>('observations', ''),
      gaps: getValue<string>('gaps', ''),
      recommendations: getValue<string>('recommendations', ''),
      compliance_status: getValue<ComplianceStatus>(
        'compliance_status',
        ComplianceStatus.DNF
      ),
      sources: getValue<ReviewSourceTypes[]>('sources', []),
      justification: getValue<string>('justification', ''),
    };

    finalGapJson.push(finalReviewResponseItem);
  });

  return finalGapJson;
};

export const handleAutoSaveDora = async ({
  agentId,
  markAsComplete,
}: AutoSaveFunctionArgs) => {
  const agentType = AGENT_TYPES.GAP_ASSESSMENT;
  const agentSubType = GapAssessmentTypes.DORA;

  // Generate the final gap JSON using the consistent type
  const gaps = JSON.stringify(generateFinalGapJSON(agentId));
  const blob = new Blob([gaps], { type: 'application/json' });

  // Fetch agent data using the correct types
  const agentData = getAgentData<typeof agentType, typeof agentSubType>(
    agentId
  );

  if (!agentData) {
    throw new Error('An error occurred');
  }

  const { stepData } = agentData;
  const { staleUrl } = agentData.mainData;
  const { setStaleUrl, updateAgentStepData } = getAgentStateActions();

  const editStepData = stepData?.find(
    (step) => step.type === AgentSessionStepType.EDIT_RESPONSE
  );

  if (!editStepData) {
    throw new Error('An error occurred while saving');
  }

  const stepUrl = editStepData?.data?.url;
  let currentStaleUrl = staleUrl || stepUrl;
  let markAsCompleteDone = false;

  if (!currentStaleUrl) {
    const signedUrl = await getSignedUrl({
      file_names: ['updated_gaps.json'],
      max_age: 86400,
    });
    const updatedStep = {
      ...editStepData,
      data: {
        url: signedUrl[0],
      },
      status: markAsComplete
        ? AgentSessionStatus.COMPLETE
        : AgentSessionStatus.IN_PROGRESS,
    };
    const updatedSteps = stepData.map((step) => {
      if (step.id === updatedStep.id) {
        return updatedStep;
      }
      return step;
    });
    updateAgentStepData(agentId, updatedSteps);
    await updateAgentSessionStep(updatedStep);
    currentStaleUrl = signedUrl[0];
    markAsCompleteDone = true;
    setStaleUrl(agentId, currentStaleUrl);
  }

  const expiryDate = new URL(currentStaleUrl).searchParams.get('se');
  if (expiryDate) {
    const expiry = new Date(expiryDate);
    const currentTime = new Date();
    const diff = expiry.getTime() - currentTime.getTime();
    if (diff < 0) {
      const signedUrl = await getSignedUrl({
        stale_urls: [currentStaleUrl],
        max_age: 86400,
      });
      currentStaleUrl = signedUrl[0];
      setStaleUrl(agentId, currentStaleUrl);
    }
  }

  await uploadFileReq(currentStaleUrl, blob);

  if (markAsComplete && !markAsCompleteDone) {
    const updatedStep = {
      ...editStepData,
      data: {
        url: currentStaleUrl,
      },
      status: AgentSessionStatus.COMPLETE,
    };
    await updateAgentSessionStep(updatedStep);
    const updatedStepData = stepData.map((step) => {
      if (step.id === updatedStep.id) {
        return updatedStep;
      }
      return step;
    });
    updateAgentStepData(agentId, updatedStepData);
  }
};

export const processFileForDoraAudit = async (
  url: string,
  sessionId: string
) => {
  const gapJson = await get<FinalDoraReviewResponse[]>({
    url,
    isAuthRequired: false,
  });

  const agentType = AGENT_TYPES.GAP_ASSESSMENT;
  const assessmentType = GapAssessmentTypes.DORA;

  const session = await getAgentSession(sessionId ?? '');
  const ids: string[] = [];
  const keysToOmit: string[] = [
    'id',
    'confidence',
    'approved',
    'edited',
    'criteria_assessments',
    'requirement_id',
    'llm_instructions',
    'trust_id',
    'trust_services_criteria',
    'control_number',
    'control',
    'category',
    'assessment_criteria',
    'retriever_questions',
    'questions',
    'chapter',
    'article',
    'requirement',
    'compliant',
    'control_id',
    'zania_control_id',
    'justification',
  ];
  const tableRows: DoraGapReviewResponseTableRow[] = [];
  const approvedIds: string[] = [];
  const editedIds: string[] = [];

  // Create a map of review responses based on the gap JSON data
  const reviewResponse = new Map<
    string,
    ReviewResponseData<
      keyof AgentSpecificReviewResponseType<
        typeof agentType,
        typeof assessmentType
      >
    >[]
  >(
    gapJson.map((gap) => {
      const id = uuid();
      ids.push(id);

      const idSplit = gap.control_id ? gap.control_id.split('.') : '';
      let tag: string;
      if (idSplit.length === 3) {
        const chapterRomanNum = idSplit[0].toUpperCase();
        tag =
          'Ch. ' +
          chapterRomanNum +
          ' Art. ' +
          idSplit[1] +
          ' Req. ' +
          idSplit[2];
      } else {
        tag = gap.control_id ? gap.control_id.split('.').join(' ') : 'N/A';
      }

      const tableRow: DoraGapReviewResponseTableRow = {
        id: id,
        tag: tag,
        confidence: gap.confidence ?? '',
        sources: gap.sources ?? [],
        requirement: gap.requirement ?? '',
        compliant: gap.compliance_status ?? ComplianceStatus.DNF,
        status: gap.approved ? 'approved' : gap.edited ? 'edited' : 'none',
      };
      tableRows.push(tableRow);
      if (gap.approved) {
        approvedIds.push(id);
      }
      if (gap.edited) {
        editedIds.push(id);
      }

      const orderedKeys = [
        'observations',
        'gaps',
        'recommendations',
        'compliance_status',
        'sources',
        'justification',
      ];

      const orderedData = orderedKeys.map((key) => ({
        type: getRenderType(key),
        value: gap[key as keyof typeof gap],
        key: key as keyof AgentSpecificReviewResponseType<
          AGENT_TYPES.GAP_ASSESSMENT,
          GapAssessmentTypes.DORA
        >,
        title: getFieldTitle(key),
        shouldRender: !keysToOmit.includes(key),
      }));

      Object.entries(gap).forEach(([key, value]) => {
        if (!orderedKeys.includes(key)) {
          const data: ReviewResponseData<
            keyof AgentSpecificReviewResponseType<
              typeof agentType,
              typeof assessmentType
            >
          > = {
            type: getRenderType(key),
            value: value as unknown,
            key: key as keyof AgentSpecificReviewResponseType<
              typeof agentType,
              typeof assessmentType
            >,
            title: getFieldTitle(key),
            shouldRender: !keysToOmit.includes(key),
          };
          orderedData.push(data);
        }
      });

      return [id, orderedData];
    })
  );

  const currentData = getAgentData<typeof agentType, typeof assessmentType>(
    sessionId
  );

  if (!currentData) {
    throw new Error(
      'An error occurred while processing the file: Failed to get agent data.'
    );
  }

  const stepData = currentData.stepData.map((step) => {
    if (step.type === AgentSessionStepType.DORA_ASSESSMENT) {
      step.status = AgentSessionStatus.COMPLETE;
    }
    if (step.type === AgentSessionStepType.EDIT_RESPONSE) {
      step.status = AgentSessionStatus.INPUT_NEEDED;
    }
    return step;
  });

  const agentData: Partial<AgentData<typeof agentType, typeof assessmentType>> =
    {
      agentType: agentType,
      sessionData: session.data.session as DoraAgentSession,
      stepData,
      mainData: {
        ...currentData.mainData,
        reviewResponseData: reviewResponse,
        reviewResponseIds: ids,
        tableRows,
        searchFor: ['requirement', 'tag'],
        searchTerm: '',
        approvedIds,
        editedIds,
      },
    };

  const { updateAgentData } = getAgentStateActions();

  updateAgentData<AGENT_TYPES.GAP_ASSESSMENT, GapAssessmentTypes.DORA>(
    sessionId,
    agentData
  );
};

export const getDoraJsonFromApi = async (sessionId: string) => {
  try {
    // Define agentType and assessmentType at the top
    const agentType = AGENT_TYPES.GAP_ASSESSMENT;
    const assessmentType = GapAssessmentTypes.DORA;

    // Generate the final gap JSON for DORA
    const gaps = JSON.stringify(generateFinalGapJSON(sessionId));
    const blob = new Blob([gaps], { type: 'application/json' });

    const signedUrl = await getSignedUrl({
      file_names: ['updated_gap.json'],
      max_age: 86400,
    });

    await uploadFileReq(signedUrl[0], blob);

    // Fetch agent data using the defined types
    const agentData = getAgentData<typeof agentType, typeof assessmentType>(
      sessionId
    );

    if (!agentData) {
      throw new Error('An error occurred');
    }

    const { responseQuality, sessionData, stepData } = agentData;

    const data = await getRAGExcelFromJSON({
      task: 'dora_assessment',
      output_format: 'excel',
      json_url: signedUrl[0],
      response_quality: responseQuality ?? AiResponseType.LITE,
    });

    const { setFinalFileUrl, updateAgentStepData } = getAgentStateActions();

    setFinalFileUrl(sessionId, data.data.file_url);

    const response = await fetch(data.data.file_url);
    const fileBlob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(fileBlob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = `${sessionData.name}_dora_gap_assessment.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);

    addNotification({
      type: 'success',
      title: 'Downloading your Report...',
      message:
        'If your report download does not start within 15 seconds, please use the download button',
    });

    const downloadStepData = stepData?.find(
      (step) => step.type === AgentSessionStepType.PREPARE_REPORT
    );
    const editResponseStep = stepData?.find(
      (step) => step.type === AgentSessionStepType.EDIT_RESPONSE
    );
    const updatedStepData = stepData.map((step) => {
      if (step.id === editResponseStep?.id) {
        return { ...step, status: AgentSessionStatus.COMPLETE };
      }
      if (step.id === downloadStepData?.id) {
        return {
          ...step,
          data: {
            url: data.data.file_url,
          },
        };
      }
      return step;
    });

    if (downloadStepData?.id) {
      await updateAgentSessionStep({
        ...downloadStepData,
        data: {
          url: data.data.file_url,
        },
      });
    }

    updateAgentStepData(sessionId, updatedStepData as AgentSessionStep[]);
  } catch (error) {
    addNotification({
      type: 'error',
      message: 'Error in downloading the file',
      title: 'Error',
    });
  }
};

export const handleEndDoraSession = async (
  sessionId: string,
  navigate: NavigateFunction
) => {
  try {
    const { setFinalFileUrl, updateAgentStepData } = getAgentStateActions();
    const agentData = getAgentData<
      AGENT_TYPES.GAP_ASSESSMENT,
      GapAssessmentTypes.DORA
    >(sessionId);
    if (!agentData) {
      throw new Error('An error occurred');
    }
    const { stepData } = agentData;

    const editResponseStep = stepData?.find(
      (step) => step.type === AgentSessionStepType.EDIT_RESPONSE
    );

    //if user directly clicks on end session without download report
    if (editResponseStep?.status != AgentSessionStatus.COMPLETE) {
      // Define agentType and assessmentType at the top
      const agentType = AGENT_TYPES.GAP_ASSESSMENT;
      const assessmentType = GapAssessmentTypes.DORA;

      // Generate the final gap JSON for DORA
      const gaps = JSON.stringify(generateFinalGapJSON(sessionId));
      const blob = new Blob([gaps], { type: 'application/json' });

      const signedUrl = await getSignedUrl({
        file_names: ['updated_gap.json'],
        max_age: 86400,
      });

      await uploadFileReq(signedUrl[0], blob);

      const editResponseStep = stepData?.find(
        (step) => step.type === AgentSessionStepType.EDIT_RESPONSE
      );

      // Process the JSON file first
      await processFileForDoraAudit(signedUrl[0], sessionId);

      const data = await getRAGExcelFromJSON({
        task: 'dora_assessment',
        output_format: 'excel',
        json_url: signedUrl[0],
        response_quality: agentData.responseQuality ?? AiResponseType.LITE,
      });
      setFinalFileUrl(sessionId, data.data.file_url);

      const downloadStepData = stepData?.find(
        (step) => step.type === AgentSessionStepType.PREPARE_REPORT
      );
      
      const updatedStepData = stepData.map((step) => {
        if (step.id === editResponseStep?.id) {
          return {
            ...step,
            status: AgentSessionStatus.COMPLETE,
            data: {
              url: signedUrl[0], //  JSON URL for future processing
            },
          };
        }
        if (step.id === downloadStepData?.id) {
          return {
            ...step,
            data: {
              url: data.data.file_url,
            },
            status: AgentSessionStatus.COMPLETE,
          };
        }
        return step;
      });

      if (downloadStepData?.id) {
        await updateAgentSessionStep({
          ...downloadStepData,
          data: {
            url: data.data.file_url,
          },
          status: AgentSessionStatus.COMPLETE,
        });
      }

      updateAgentStepData(sessionId, updatedStepData as AgentSessionStep[]);
      const successPath = `/${ROUTES.AGENT}/${AGENT_ROUTES.SUCCESS}/${sessionId}`;
      navigate(successPath);
    } else {
      const prepareReportStep = stepData?.find(
        (step) => step.type === AgentSessionStepType.PREPARE_REPORT
      );
      const updatedStepData = stepData.map((step) => {
        if (step.id === prepareReportStep?.id) {
          return { ...step, status: AgentSessionStatus.COMPLETE };
        }
        return step;
      });
      if (prepareReportStep?.id) {
        await updateAgentSessionStep({
          ...prepareReportStep,
          status: AgentSessionStatus.COMPLETE,
        });
      }
      await new Promise<void>((resolve) => {
        updateAgentStepData(sessionId, updatedStepData as AgentSessionStep[]);
        setTimeout(resolve, 0);
      });
      const successPath = `/${ROUTES.AGENT}/${AGENT_ROUTES.SUCCESS}/${sessionId}`;
      navigate(successPath);
    }
  } catch (error) {
    addNotification({
      type: 'error',
      message: 'Error in ending the session',
      title: 'Error',
    });
  }
};
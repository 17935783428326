import {
  removeNotification,
  useNotificationAction,
  useNotificationState,
} from '@/shared/states/notification';
import { FC, useEffect } from 'react';
import { createPortal } from 'react-dom';
import Notification from '.';
import { AnimatePresence } from 'framer-motion';

export const NotificationsProvider: FC<{
  maxNotifications?: number;
}> = ({ maxNotifications }) => {
  const { notificationKeys } = useNotificationState();
  const { setState } = useNotificationAction();

  const totalNotificationKeys = notificationKeys.length - 1;

  useEffect(() => {
    if (maxNotifications && totalNotificationKeys >= maxNotifications) {
      const firstNotification = notificationKeys[0];
      const newNotifications = notificationKeys.slice(1);

      removeNotification(firstNotification);
      setState({ notificationKeys: newNotifications });
    }
  }, [notificationKeys, totalNotificationKeys, maxNotifications, setState]);

  const portalContainer = document.getElementById('portal')!;

  return (
    <>
      {createPortal(
        <div className="absolute gap-3 bottom-5 right-5 z-[99999] flex flex-col">
          <AnimatePresence>
            {notificationKeys.map((id) => {
              return <Notification key={id} id={id} />;
            })}
          </AnimatePresence>
        </div>,
        portalContainer
      )}
    </>
  );
};

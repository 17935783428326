import { del, put } from '@/infra/rest';
import { AxiosProgressEvent } from 'axios';

export const uploadFileReq = async (
  url: string,
  file: File | File[] | Blob,
  progressCallback?: (progressEvent: AxiosProgressEvent) => void
) => {
  // const formData = new FormData();

  // if (Array.isArray(file)) {
  //   file.forEach((f) => {
  //     formData.append('files', f);
  //   });
  // } else {
  //   formData.append('file', file);
  // }
  const urlObj = new URL(url);

  return await put({
    url: urlObj.pathname + urlObj.search,
    data: file,
    isAuthRequired: false,
    config: {
      baseURL: urlObj.origin,
      headers: {
        'x-ms-blob-type': 'BlockBlob',
        ...(!Array.isArray(file) ? { 'Content-Type': file.type } : {}),
      },
      onUploadProgress: progressCallback,
    },
  });
};

export const deleteFileReq = async (url: string) => {
  const urlObj = new URL(url);

  return await del({
    url: urlObj.pathname + urlObj.search,
    isAuthRequired: false,
    config: {
      baseURL: urlObj.origin,
    },
  });
};

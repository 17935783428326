import { ROUTES } from '@/shared/constants/routes';
import { importLazy } from '@/shared/utils/import-lazy';
import { RouteObject } from 'react-router-dom';

export const AgentSessionRoutes: RouteObject[] = [
  {
    path: ROUTES.AGENT_SESSIONS,
    lazy: importLazy(() => import('@/modules/sessions/components')),
    children: [],
  },
];

import { useTeamActions } from '@/modules/team/states';
import useQuery from './use-query';
import {
  useStytchB2BClient,
  useStytchMember,
  useStytchOrganization,
} from '@stytch/react/b2b';
import { useStytchActions } from '../states/stytch';
import { useEffect } from 'react';
import { allowedEmails, useUserStateSelector } from '../states/user';

const useLoadDataOnAppLoad = (isAuthenticated: boolean) => {
  const { setTeam, setPermissions } = useTeamActions();
  const stytch = useStytchB2BClient();
  const member = useStytchMember();
  const organization = useStytchOrganization();

  const { setStytch } = useStytchActions();
  const setUserAllowed = useUserStateSelector.useSetUserAllowed();

  useEffect(() => {
    if (!isAuthenticated) return;
    const userEmail = member.member?.email_address;
    if (!userEmail) return;
    const isAllowed = allowedEmails.includes(userEmail);
    if (isAllowed) {
      setUserAllowed(true);
    }
  }, [isAuthenticated, member.member?.email_address, setUserAllowed]);

  useEffect(() => {
    setStytch(stytch);
  }, [stytch, setStytch]);

  useQuery({
    queryKey: ['users'],
    setter: setTeam,
    queryFn: async () => {
      const { members } = await stytch.organization.members.search({
        limit: 1000,
      });
      return members;
    },
    retry: 3,
    enabled: isAuthenticated,
  });

  useQuery({
    queryKey: ['permissions'],
    setter: setPermissions,
    queryFn: async () => {
      const permissions = await stytch.rbac.allPermissions();
      return permissions;
    },
    retry: 3,
    enabled: isAuthenticated,
  });

  return { stytch, member, organization };
};

export default useLoadDataOnAppLoad;

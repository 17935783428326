import { io, SocketOptions, ManagerOptions, Socket } from 'socket.io-client';
import requestConfig from '../../shared/config/request';
import { getSessionJWT } from '../stytch';

export const getSocketOptions = (
  token: string
): Partial<SocketOptions | ManagerOptions> => ({
  transports: ['websocket'],
  upgrade: true,
  rememberUpgrade: false,
  forceNew: true,
  closeOnBeforeunload: false,
  reconnection: true,
  reconnectionAttempts: 3,
  withCredentials: true,
  path: requestConfig.socketPath,
  extraHeaders: {
    Authorization: token,
  },
  query: {
    token: token,
  },
});

export let mainSocket: Socket | undefined = undefined;

export const getZaniaSocket = async (refresh?: boolean): Promise<Socket> => {
  const token = (await getSessionJWT(refresh)) as string;

  if (!token) {
    throw new Error(
      'Failed to authenticate. Please try logging in again. Contact Zania support if the issue persists.'
    );
  }

  if (mainSocket) {
    mainSocket.io.opts.extraHeaders = { Authorization: token };
    mainSocket.io.opts.query = { token };
    return mainSocket;
  }

  mainSocket = io(`${requestConfig.socketUrl}/`, getSocketOptions(token));

  return mainSocket;
};

import { sessionDurationMins } from '@/modules/auth/constants';
import appConfig from '@/shared/config/app';
import { StytchB2BUIClient } from '@stytch/vanilla-js/b2b';

const stytch = new StytchB2BUIClient(appConfig.stytchKey);

const getSessionJWT = async (refresh?: boolean): Promise<string> => {
  if (!stytch.session) {
    throw new Error(
      'Authentication failed. Please try logging out and logging in again. Contact support if the issue persists.'
    );
  }

  if (refresh === undefined || refresh || !stytch.session.getSync()) {
    await stytch.session.authenticate({
      session_duration_minutes: sessionDurationMins,
    });
  }

  const data = stytch.session.getTokens();
  if (!data) {
    throw new Error(
      'Authentication failed. Please try logging out and logging in again. Contact support if the issue persists.'
    );
  }

  return data.session_jwt;
};

export { getSessionJWT, stytch };

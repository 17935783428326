/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  createAgentSession,
  getAgentSession,
  updateAgentSessionStep,
} from '@/modules/sessions/requests';
import {
  AgentSessionStatus,
  AgentSessionStep,
  AgentSessionStepType,
  AgentSessionType,
  MultiFileVendorCreateRequest,
  MultiFileVendorRagCreateRequestInput,
  MultiVendorAgentSession,
} from '@/modules/sessions/types';
import { getAgentData, getAgentStateActions } from '../states';

import { getSessionJWT } from '@/infra/stytch';
import { getSignedUrl } from '@/shared/requests/get-signed-url';
import { uploadFileReq } from '@/shared/requests/upload-file';
import { addNotification } from '@/shared/states/notification';
import { userStateSelector } from '@/shared/states/user';
import { AiResponseType } from '@/shared/types/user';
import { removeSearchParams } from '@/shared/utils/remove-search-params';
import { NavigateFunction } from 'react-router-dom';
import { useVendorRiskAssessmentStore } from '../components/VendorRiskAssessment/useVendorRiskAssessment/useVendorRiskAssessmentStore';
import { emitRagCreate, getRAGExcelFromJSON } from '../requests';
import {
  AGENT_TYPES,
  AgentData,
  AgentSourceFile,
  MultiVendorAssessmentTypes,
} from '../types/index.ts';
import { VendorIntakeFormData } from '../types/vendorIntakeForm.ts';
import { getResponseTypes } from '../utils/get-response-type';
import { handleSocketResponse } from '../utils/socket-response';
import { AutoSaveFunctionArgs } from '../utils/autosave';
import { generateFinalVendorReviewResponseData } from './vendor-risk.use-case';
import { ROUTES } from '@/shared/constants/routes';
import { AGENT_ROUTES } from '@/shared/constants/routes';

export const handleAutoSaveOfVendorIntakeForm = async (
  agentId: string,
  formData?: VendorIntakeFormData,
  signedUrls?: string[],
  markAsComplete?: boolean
) => {
  const agentData = getAgentData<
    AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT,
    MultiVendorAssessmentTypes.VENDOR_ASSESSMENT
  >(agentId);
  const actions = getAgentStateActions();

  if (!agentData) {
    throw new Error('An error occurred');
  }

  const { stepData, mainData } = agentData;

  // if (mainData.justLoaded) {
  //   actions.updateAgentMainData(agentId, { ...mainData, justLoaded: false });
  //   return;
  // }

  const vendorProfileStep = stepData?.find(
    (step) => step.type === AgentSessionStepType.MULTI_VENDOR_PROFILE
  );
  if (!vendorProfileStep) {
    throw new Error('An error occurred while saving');
  }

  // Compare new data with current data
  const currentFormData =
    vendorProfileStep.data?.vendor_intake_form || ({} as VendorIntakeFormData);
  const currentSignedUrls = vendorProfileStep.data?.source_urls || [];

  const hasFormDataChanged =
    JSON.stringify(currentFormData) !== JSON.stringify(formData);
  const hasSignedUrlsChanged =
    JSON.stringify(currentSignedUrls) !== JSON.stringify(signedUrls);

  if (!hasFormDataChanged && !hasSignedUrlsChanged && !markAsComplete) {
    return;
  }

  const updatedStep = {
    ...vendorProfileStep,
    data: {
      ...vendorProfileStep.data,
      vendor_intake_form: formData,
      source_urls: signedUrls,
    },
    status: markAsComplete
      ? AgentSessionStatus.COMPLETE
      : AgentSessionStatus.IN_PROGRESS,
  };

  const updatedSteps = stepData.map((step) =>
    step.id === updatedStep.id ? updatedStep : step
  );

  actions.updateAgentStepData(agentId, updatedSteps as AgentSessionStep[]);

  try {
    await updateAgentSessionStep(updatedStep as AgentSessionStep);
  } catch (error) {
    console.error('Error updating agent session step:', error);
    addNotification({
      message: 'Failed to save vendor profile. Please try again.',
      type: 'error',
    });
  }
};

export const processFileForVendorAssessment = async (
  agentId: string,
  navigate: NavigateFunction
) => {
  const selectedResponse = userStateSelector.getState().aiResponseType;
  const agentData = getAgentData<
    AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT,
    MultiVendorAssessmentTypes.VENDOR_ASSESSMENT
  >(agentId);
  if (!agentData) {
    throw new Error('An error occurred');
  }
  let { stepData } = agentData;
  const vendorProfileStep = stepData.find(
    (step) => step.type === AgentSessionStepType.MULTI_VENDOR_PROFILE
  );
  if (!vendorProfileStep) {
    throw new Error('Vendor profile step not found');
  }
  const sourceUrls = vendorProfileStep.data?.source_urls || [];
  const cleanedSourceUrls = sourceUrls.map(removeSearchParams);
  const { response_mode, response_quality } =
    getResponseTypes(selectedResponse);

  const vendorAssessment: MultiFileVendorRagCreateRequestInput = {
    doc_type: 'json',
    response_quality,
    source_urls: cleanedSourceUrls,
    vendor_intake_form: vendorProfileStep.data?.vendor_intake_form,
  };
  stepData = stepData.map((step) => {
    if (
      [
        AgentSessionStepType.LOAD_TEMPLATE,
        AgentSessionStepType.EXTRACT_CONTROLS,
        AgentSessionStepType.MULTI_VENDOR_PROFILE,
      ].includes(step.type)
    ) {
      step.status = AgentSessionStatus.COMPLETE;
    }
    if (
      (step.type as AgentSessionStepType) ===
      AgentSessionStepType.MULTI_FILE_VENDOR_ASSESSMENT
    ) {
      step.status = AgentSessionStatus.IN_PROGRESS;
    }
    return step;
  });
  const { setAgentData } = getAgentStateActions();
  setAgentData(agentId, {
    ...agentData,
    stepData,
  });

  const multiFileVendorAssessmentStep = stepData.find(
    (step): step is AgentSessionStep =>
      (step.type as AgentSessionStepType) ===
      AgentSessionStepType.MULTI_FILE_VENDOR_ASSESSMENT
  );

  const agent_session_step_id = multiFileVendorAssessmentStep?.id ?? '';

  const vendorProfileStep1 = stepData.find(
    (
      step
    ): step is AgentSessionStep & {
      type: AgentSessionStepType.MULTI_VENDOR_PROFILE;
    } => step.type === AgentSessionStepType.MULTI_VENDOR_PROFILE
  );

  const updatedStep = {
    ...vendorProfileStep1,
    status: AgentSessionStatus.COMPLETE,
  };
  try {
    await updateAgentSessionStep(updatedStep as AgentSessionStep);
  } catch (error) {
    console.error('Error updating agent session step:', error);
    addNotification({
      message: 'Failed to save vendor profile. Please try again.',
      type: 'error',
    });
  }

  emitRagCreate(
    {
      agent_session_id: agentId,
      agent_session_step_id,
      token: (await getSessionJWT()) ?? '',
      rag_input: vendorAssessment,
      response_mode,
      task: 'multi_file_vendor_assessment',
      vendor_intake_form: vendorProfileStep.data?.vendor_intake_form,
    },
    (response: any) => {
      handleSocketResponse(response, navigate);
    }
  ).catch((error) => {
    console.error('Error emitting RAG create:', error);
  });
  navigate({
    pathname: `/agent/${agentId}/`,
  });
};

export const handleAutoSaveOfVendorAssessmentResponse = async ({
  agentId,
  markAsComplete,
  data,
}: AutoSaveFunctionArgs): Promise<void> => {
  const reviewResponseData = generateFinalVendorReviewResponseData(agentId);

  const risks = JSON.stringify([
    ...useVendorRiskAssessmentStore.getState().vendorOverview,
    ...useVendorRiskAssessmentStore.getState().vendorSupplyChainData,
    ...reviewResponseData,
  ]);

  const blob = new Blob([risks], { type: 'application/json' });

  const agentData = getAgentData<
    AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT,
    MultiVendorAssessmentTypes.VENDOR_ASSESSMENT
  >(agentId);

  if (!agentData) {
    throw new Error('An error occurred');
  }
  const { stepData } = agentData;
  const { staleUrl } = agentData.mainData;

  const { setStaleUrl, updateAgentStepData } = getAgentStateActions();

  const editStepData = stepData?.find(
    (step) => step.type === AgentSessionStepType.EDIT_RESPONSE
  );

  if (!editStepData) {
    throw new Error('An occurred while saving');
  }

  const stepUrl = editStepData?.data?.url;

  let currentStaleUrl = staleUrl || stepUrl;
  let markAsCompleteDone = false;

  // eslint-disable-next-line no-constant-condition
  if (!currentStaleUrl.includes('signed_urls')) {
    const signedUrl = await getSignedUrl({
      file_names: [currentStaleUrl],
      max_age: 86400,
    });
    const updatedStep = {
      ...editStepData,
      data: {
        url: signedUrl[0],
      },
      status: markAsComplete
        ? AgentSessionStatus.COMPLETE
        : AgentSessionStatus.IN_PROGRESS,
    };
    const updatedSteps = stepData.map((step) => {
      if (step.id === updatedStep.id) {
        return updatedStep;
      }
      return step;
    });
    updateAgentStepData(agentId, updatedSteps);
    await updateAgentSessionStep(updatedStep);
    currentStaleUrl = signedUrl[0];
    markAsCompleteDone = true;
    setStaleUrl(agentId, currentStaleUrl);
  }

  const expiryDate = new URL(currentStaleUrl).searchParams.get('se');
  if (expiryDate) {
    const expiry = new Date(expiryDate);
    const currentTime = new Date();
    const diff = expiry.getTime() - currentTime.getTime();
    if (diff < 0) {
      const signedUrl = await getSignedUrl({
        stale_urls: [currentStaleUrl],
        max_age: 86400,
      });
      currentStaleUrl = signedUrl[0];
      setStaleUrl(agentId, currentStaleUrl);
    }
  }
  await uploadFileReq(currentStaleUrl, blob);

  if (markAsComplete && !markAsCompleteDone) {
    const updatedStep = {
      ...editStepData,
      data: {
        url: currentStaleUrl,
      },
      status: AgentSessionStatus.COMPLETE,
    };
    await updateAgentSessionStep(updatedStep);
    const updatedStepData = stepData.map((step) => {
      if (step.id === updatedStep.id) {
        return updatedStep;
      }
      return step;
    });
    updateAgentStepData(agentId, updatedStepData);
  }
};

export const processFileForVendorAssessmentResponse = async (
  url: string,
  sessionId: string
) => {
  const currentData = getAgentData<
    AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT,
    never
  >(sessionId);

  if (!currentData) {
    throw new Error('An error occurred');
  }

  const stepData = currentData.stepData.map((step) => {
    if (step.type === AgentSessionStepType.MULTI_FILE_VENDOR_ASSESSMENT) {
      step.status = AgentSessionStatus.COMPLETE;
    }
    if (step.type === AgentSessionStepType.EDIT_RESPONSE) {
      step.status = AgentSessionStatus.INPUT_NEEDED;
      step.data = { url };
    }
    return step;
  });

  const { updateAgentData } = getAgentStateActions();

  const agentData: Partial<
    AgentData<AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT, never>
  > = {
    ...currentData,
    stepData,
  };

  updateAgentData<AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT, never>(
    sessionId,
    agentData
  );
};

export const getVendorJsonFromApi = async (agentId: string) => {
  try {
    const risks = JSON.stringify([
      ...useVendorRiskAssessmentStore.getState().vendorOverview,
      ...useVendorRiskAssessmentStore.getState().vendorSupplyChainData,
      ...useVendorRiskAssessmentStore.getState().vendorAssessmentData,
    ]);
    const blob = new Blob([risks], { type: 'application/json' });
    const signedUrl = await getSignedUrl({
      file_names: ['updated_risks.json'],
      max_age: 86400,
    });

    await uploadFileReq(signedUrl[0], blob);
    const agentData = getAgentData<
      AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT,
      MultiVendorAssessmentTypes.VENDOR_ASSESSMENT
    >(agentId);

    if (!agentData) {
      throw new Error('An error occurred');
    }

    const { responseQuality, sessionData, stepData } = agentData;

    const data = await getRAGExcelFromJSON({
      task: 'multi_file_vendor_assessment',
      output_format: 'pdf',
      json_url: signedUrl[0],
      response_quality: responseQuality ?? AiResponseType.LITE,
    });

    const { setFinalFileUrl, updateAgentStepData } = getAgentStateActions();

    setFinalFileUrl(agentId, data.data.file_url);

    // Download the file
    const response = await fetch(data.data.file_url);
    const fileBlob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(fileBlob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = `${sessionData.name}_third_party_risk_assessment.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);

    addNotification({
      type: 'success',
      title: 'Downloading your Report...',
      message:
        'If your report download does not start within 15 seconds, please use the download button',
    });

    const downloadStepData = stepData?.find(
      (step) => step.type === AgentSessionStepType.PREPARE_REPORT
    );
    const editResponseStepData = stepData?.find(
      (step) => step.type === AgentSessionStepType.EDIT_RESPONSE
    );

    const updatedStepData = stepData.map((step) => {
      if (step.id === editResponseStepData?.id) {
        return {
          ...step,
          status: AgentSessionStatus.COMPLETE,
        };
      }
      if (step.id === downloadStepData?.id) {
        return {
          ...step,
          data: {
            url: data.data.file_url,
          },
        };
      }
      return step;
    });

    if (downloadStepData?.id) {
      await updateAgentSessionStep({
        ...downloadStepData,
        data: {
          url: data.data.file_url,
        },
      });
    }

    updateAgentStepData(agentId, updatedStepData as AgentSessionStep[]);

    return data.data.file_url;
  } catch (error) {
    console.error('Error in downloading vendor assessment:', error);
    addNotification({
      type: 'error',
      message: 'Error in downloading the file. Please try again.',
      title: 'Error',
    });
    throw error;
  }
};

interface ProcessDataForVendorAssessmentArgs {
  name: string;
  multi_file_vendor_assessment: {
    vendor_name: string;
    scope_of_integration: string;
  };
  sourceFiles: AgentSourceFile[];
  navigate: NavigateFunction;
}

export const processDataForVendorAssessment = async ({
  name,
  navigate,
  multi_file_vendor_assessment,
}: ProcessDataForVendorAssessmentArgs) => {
  const selectedResponse = userStateSelector.getState().aiResponseType;

  const { response_mode, response_quality } =
    getResponseTypes(selectedResponse);

  const vendorAssessment: MultiFileVendorCreateRequest = {
    doc_type: 'json',
    response_quality,
    source_urls: [],
    vendor_name: multi_file_vendor_assessment.vendor_name,
    scope_of_integration: multi_file_vendor_assessment.scope_of_integration,
  };

  const {
    data: { session, steps = [] },
  } = await createAgentSession({
    name: name ?? 'Vendor Assessment',
    type: AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT,
    [AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT]: vendorAssessment,
  });

  if (!session || !session.id) {
    throw new Error('An error occurred');
  }

  const stepData = steps.reduce((acc, step) => {
    if (step.type === AgentSessionStepType.MULTI_VENDOR_PROFILE) {
      step.status = AgentSessionStatus.IN_PROGRESS;
    }
    acc.push(step);
    return acc;
  }, [] as AgentSessionStep[]);

  const agentData: AgentData<
    AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT,
    MultiVendorAssessmentTypes.VENDOR_ASSESSMENT
  > = {
    agentType: AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT,
    sessionData: session as MultiVendorAgentSession,
    stepData,
    subType: MultiVendorAssessmentTypes.VENDOR_ASSESSMENT,
    responseQuality: selectedResponse,
    mainData: {
      sourceFilesUrls: new Map(),
      approvedIds: [],
      editedIds: [],
      vendorName: multi_file_vendor_assessment.vendor_name,
      scopeOfIntegration: multi_file_vendor_assessment.scope_of_integration,
    },
  };

  const { setAgentData } = getAgentStateActions();
  setAgentData(session.id, agentData);

  navigate({
    pathname: `/agent/${session.id}/`,
  });
};

export const handleEndVendorAssessment = async (
  sessionId: string,
  navigate: NavigateFunction
) => {
  const { setFinalFileUrl, updateAgentStepData } = getAgentStateActions();
  try {
    const agentData = getAgentData<
      AGENT_TYPES.MULTI_FILE_VENDOR_ASSESSMENT,
      MultiVendorAssessmentTypes.VENDOR_ASSESSMENT
    >(sessionId);

    if (!agentData) {
      throw new Error('An error occurred');
    }

    const { stepData } = agentData;

    const editResponseStepData = stepData?.find(
      (step) => step.type === AgentSessionStepType.EDIT_RESPONSE
    );

    if (!editResponseStepData) {
      throw new Error('An error occurred');
    }

    //if user directly clicks on end session without download report
    if (editResponseStepData.status != AgentSessionStatus.COMPLETE) {
      const risks = JSON.stringify([
        ...useVendorRiskAssessmentStore.getState().vendorOverview,
        ...useVendorRiskAssessmentStore.getState().vendorSupplyChainData,
        ...useVendorRiskAssessmentStore.getState().vendorAssessmentData,
      ]);
      const blob = new Blob([risks], { type: 'application/json' });
      const signedUrl = await getSignedUrl({
        file_names: ['updated_risks.json'],
        max_age: 86400,
      });
      await uploadFileReq(signedUrl[0], blob);
      const editResponseStep = stepData?.find(
        (step) => step.type === AgentSessionStepType.EDIT_RESPONSE
      );
      await processFileForVendorAssessmentResponse(signedUrl[0], sessionId);

      const data = await getRAGExcelFromJSON({
        task: 'multi_file_vendor_assessment',
        output_format: 'pdf',
        json_url: signedUrl[0],
        response_quality: agentData.responseQuality ?? AiResponseType.LITE,
      });
      console.log('data', data);
      setFinalFileUrl(sessionId, data.data.file_url);

      const downloadStepData = stepData?.find(
        (step) => step.type === AgentSessionStepType.PREPARE_REPORT
      );
      const updatedStepData = stepData.map((step) => {
        if (step.id === editResponseStepData?.id) {
          return {
            ...step,
            status: AgentSessionStatus.COMPLETE,
            data: {
              url: signedUrl[0],
            },
          };
        }
        if (step.id === downloadStepData?.id) {
          return {
            ...step,
            data: {
              url: data.data.file_url,
            },
            status: AgentSessionStatus.COMPLETE,
          };
        }
        return step;
      });
      if (downloadStepData?.id) {
        await updateAgentSessionStep({
          ...downloadStepData,
          data: {
            url: data.data.file_url,
          },
          status: AgentSessionStatus.COMPLETE,
        });
      }
      updateAgentStepData(sessionId, updatedStepData as AgentSessionStep[]);
      const successPath = `/${ROUTES.AGENT}/${AGENT_ROUTES.SUCCESS}/${sessionId}`;
      navigate(successPath);
    } else {
      const prepareReportStep = stepData?.find(
        (step) => step.type === AgentSessionStepType.PREPARE_REPORT
      );
      const updatedStepData = stepData.map((step) => {
        if (step.id === prepareReportStep?.id) {
          return { ...step, status: AgentSessionStatus.COMPLETE };
        }
        return step;
      });
      if (prepareReportStep?.id) {
        await updateAgentSessionStep({
          ...prepareReportStep,
          status: AgentSessionStatus.COMPLETE,
        });
      }
      await new Promise<void>((resolve) => {
        updateAgentStepData(sessionId, updatedStepData as AgentSessionStep[]);
        setTimeout(resolve, 0);
      });
      const successPath = `/${ROUTES.AGENT}/${AGENT_ROUTES.SUCCESS}/${sessionId}`;
      navigate(successPath);
    }
  } catch (error) {
    addNotification({
      type: 'error',
      message: 'Error in ending the session',
      title: 'Error',
    });
  }
};

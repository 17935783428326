import {
  useQuery as baseQuery,
  UseQueryOptions,
  UseQueryResult,
  QueryKey,
} from '@tanstack/react-query';

import { useEffect } from 'react';

interface UseQueryWrapperOptions<TData, TError, TQueryKey extends QueryKey>
  extends UseQueryOptions<TData, TError, TData, TQueryKey> {
  setter?: (data: TData) => void;
}

function useQuery<TData, TError, TQueryKey extends QueryKey>(
  options: UseQueryWrapperOptions<TData, TError, TQueryKey>
): UseQueryResult<TData, TError> {
  const { setter, ...queryOptions } = options;

  const queryResult = baseQuery<TData, TError, TData, TQueryKey>({
    ...queryOptions,
  });

  useEffect(() => {
    if (queryResult.data && setter) {
      setter(queryResult.data);
    }
  }, [queryResult.data, setter]);

  return queryResult;
}

export default useQuery;

import { SVGProps } from 'react';

const ZaniaIcon = ({ fill, ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="64.000000pt"
      height="64.000000pt"
      viewBox="0 0 64.000000 64.000000"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g
        transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
        fill={fill ?? '#000000'}
        stroke="none"
      >
        <path d="M76 535 c-42 -53 -76 -101 -76 -106 0 -14 92 -55 165 -74 69 -18 163 -19 235 -4 49 10 54 15 119 102 37 51 70 97 74 104 6 9 -27 11 -135 10 -137 -2 -146 -1 -208 26 -36 16 -72 31 -81 34 -12 3 -39 -24 -93 -92z" />
        <path d="M225 289 c-34 -8 -48 -20 -95 -86 -30 -42 -63 -89 -72 -103 l-18 -26 143 0 c135 1 145 0 207 -27 36 -16 72 -31 80 -33 10 -4 40 25 88 86 40 50 75 97 78 105 8 19 -44 50 -125 75 -68 22 -211 26 -286 9z" />
      </g>
    </svg>
  );
};

export default ZaniaIcon;

/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import { AiResponseType } from '../types/user';
import { create } from 'zustand';
import {
  createSelectorFunctions,
  createSelectorHooks,
} from 'auto-zustand-selectors-hook';
import { ENVTYPE } from '../constants';
import { persist, createJSONStorage } from 'zustand/middleware';

export const allowedEmails = [
  'sahil@zania.ai',
  'harsh+1@zania.ai',
  'aalem@zania.ai',
  'shruti@zania.ai',
];

interface DemoState {
  isDemoActive: boolean;
  isUserAllowed: boolean;
}

interface UserState {
  demo: DemoState;
  aiResponseType: AiResponseType;
  isSlackConnected: boolean;
}

const initialState: UserState = {
  demo: {
    isDemoActive: false,
    isUserAllowed: false,
  },
  // Env Condition
  aiResponseType: AiResponseType.NONE,
  isSlackConnected: false,
};

interface UserActions {
  setDemoActive: (isActive: boolean) => void;
  setUserAllowed: (isAllowed: boolean) => void;
  setAIResponseType: (aiResponseType: AiResponseType) => void;
  setSlackConnected: (isConnected: boolean) => void;
}

type UserStore = UserState & UserActions;

export const useUserStore = create<UserStore>()(
  persist(
    (set) => ({
      ...initialState,
      setAIResponseType: (aiResponseType) => set({ aiResponseType }),
      setSlackConnected: (isSlackConnected) => set({ isSlackConnected }),
      setDemoActive: (isDemoActive) =>
        set((state) => ({
          demo: {
            ...state.demo,
            isDemoActive,
          },
        })),
      setUserAllowed: (isUserAllowed) =>
        set((state) => ({
          demo: {
            ...state.demo,
            isUserAllowed,
          },
        })),
    }),
    {
      name: 'user-storage',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export const userStateSelector = createSelectorFunctions(useUserStore);

export const useUserStateSelector = createSelectorHooks(useUserStore);

export const useIsDemoActive = () =>
  useUserStateSelector.useDemo().isDemoActive;

import { ENVTYPE } from '@/shared/constants';
import posthog from 'posthog-js';

export const initPostHog = () => {
  posthog.init('phc_un9S5phAgyIS4H7hpF3Qg9Pq6rdoQowr2zKXA6ctVSl', {
    api_host: 'https://us.i.posthog.com',
    loaded: (posthog) => {
      const mode = import.meta.env.MODE as ENVTYPE;
      if ([ENVTYPE.DEVELOPMENT].includes(mode)) posthog.opt_out_capturing();
    },
  });
};

import { ConfidenceTypes, ReviewSourceTypes } from ".";
import { VendorRiskResponseItemForVendorOverview } from "./vendorOverviewSection";
import { VendorRiskResponseItemForSecurityAssessment } from "./vendorSecurityAssessment";
import { VendorRiskResponseItemForVendorSupplyChain } from "./vendorSupplyChain";

export enum VendorRiskSectionEnum {
  vendor_overview = 'vendor_overview',
  vendor_supply_chain = 'vendor_supply_chain',
  zania_security_assessment = 'zania_security_assessment',
  ai_security_assessment = 'ai_security_assessment',
}

export type VendorRiskResponseMappedBySection = {
  [VendorRiskSectionEnum.vendor_overview]: VendorRiskResponseItemForVendorOverview[];
  [VendorRiskSectionEnum.vendor_supply_chain]: VendorRiskResponseItemForVendorSupplyChain[];
  [VendorRiskSectionEnum.zania_security_assessment]: VendorRiskResponseItemForSecurityAssessment[];
  [VendorRiskSectionEnum.ai_security_assessment]: VendorRiskResponseItemForSecurityAssessment[];
};

export type VendorRiskResponseItem = 
  | VendorRiskResponseItemForSecurityAssessment
  | VendorRiskResponseItemForVendorOverview
  | VendorRiskResponseItemForVendorSupplyChain;

export interface BaseLLMResponse {
  confidence: ConfidenceTypes;
  sources: ReviewSourceTypes[];
}
  
import { getZaniaSocket } from '@/infra/sockets';

import { post } from '@/infra/rest';
import {
  GetExcelFromJSONRequest,
  GetExcelFromJSONResponse,
  QuestionnaireFillerRequest,
  QuestionnaireStructureRequest,
} from '../types/questionnaire';

export const emitQuestionnaireStructure = async (
  data: QuestionnaireStructureRequest,
  callback?: (...args: any[]) => void
) => {
  const socket = await getZaniaSocket();

  socket.emit('task:qa:structure:create', data, callback);

  return true;
};

export const emitQuestionnaireFiller = async (
  data: QuestionnaireFillerRequest,
  callback?: (...args: any[]) => void
) => {
  const socket = await getZaniaSocket();

  socket.emit('task:qa:filler:create', data, callback);

  return true;
};

export const getExcelFromJSON = async (data: GetExcelFromJSONRequest) => {
  return post<GetExcelFromJSONRequest, GetExcelFromJSONResponse>({
    url: `/v0/compliance/questionnaire-filler-json-to-excel`,
    data,
  });
};

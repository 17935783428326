import { ComponentType } from 'react';

export function importLazy<T extends { default: ComponentType | null }>(
  load: () => Promise<T>
): () => Promise<Omit<T, 'default'> & { Component: ComponentType | null }> {
  return async () => {
    const { default: Component, ...rest } = await load();
    return { ...rest, Component };
  };
}

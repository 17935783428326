import { ROUTES, TEAM_ROUTES } from '@/shared/constants/routes';
import { importLazy } from '@/shared/utils/import-lazy';
import { RouteObject } from 'react-router-dom';

export const TeamRoutes: RouteObject[] = [
  {
    path: ROUTES.TEAM,
    lazy: importLazy(() => import('@/modules/team/components')),
    children: [
      {
        path: TEAM_ROUTES.INVITE,
        lazy: importLazy(() => import('../components/invite-dialog')),
      },
      {
        path: TEAM_ROUTES.REMOVE_MEMBER,
        lazy: importLazy(() => import('../components/remove-dialog')),
      },
      {
        path: TEAM_ROUTES.UPDATE_MEMBER,
        lazy: importLazy(() => import('../components/update-dialog')),
      },
      {
        path: TEAM_ROUTES.TRANSFER_OWNER,
        lazy: importLazy(() => import('../components/transfer-dialog')),
      },
    ],
  },
];

import { ROUTES } from '@/shared/constants/routes';
import { importLazy } from '@/shared/utils/import-lazy';
import { LoaderFunctionArgs, redirect, RouteObject } from 'react-router-dom';
import { getAgentData } from '../states';
import { getAgentSession } from '@/modules/sessions/requests';
import {
  handleContinueMultiVendorAssessment,
  handleContinueDoraGapAssessment,
  handleContinueQuestionnaire,
  handleContinueRiskAssessment,
  handleContinueSoc2GapAssessment,
} from '@/modules/sessions/use-cases/agent-session.use-case';
import { AgentSessionType } from '@/modules/sessions/types';
import { addNotification } from '@/shared/states/notification';

const sessionTypeHandlers: {
  [key in AgentSessionType]: (params: {
    session: any;
    steps: any;
  }) => Promise<void> | void;
} = {
  [AgentSessionType.RISK_ASSESSMENT]: handleContinueRiskAssessment,
  [AgentSessionType.QA_FILLER]: handleContinueQuestionnaire,
  [AgentSessionType.SOC2_GAP]: handleContinueSoc2GapAssessment,
  [AgentSessionType.MULTI_FILE_VENDOR_ASSESSMENT]: (params) =>
    handleContinueMultiVendorAssessment(params),
  [AgentSessionType.DORA_GAP]: handleContinueDoraGapAssessment,
};

const agentLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  if (!id) {
    addNotification({
      message: 'Agent not found',
      type: 'error',
    });
    return redirect(`/${ROUTES.AGENT}`);
  }
  const agent = getAgentData(id);
  if (agent) {
    return true;
  } else {
    try {
      const agentFromApi = await getAgentSession(id);

      if (!agentFromApi || !agentFromApi.data) {
        addNotification({
          message: 'Agent not found',
          type: 'error',
        });
        return redirect(`/${ROUTES.AGENT}`);
      }
      const agentType = agentFromApi.data.session.type;
      const handler = sessionTypeHandlers[agentType];
      if (handler) {
        await handler({
          session: agentFromApi.data.session,
          steps: agentFromApi.data.steps,
        });
      }
    } catch (error) {
      addNotification({
        message: 'Error while continuing assessment.',
        type: 'error',
      });
      return redirect(`/${ROUTES.AGENT}`);
    }
    return true;
  }
};

export const AgentRoutes: RouteObject[] = [
  {
    path: ROUTES.AGENT,
    children: [
      {
        path: ':id',
        lazy: importLazy(() => import('@/modules/agent/components/layout')),
        loader: agentLoader,
      },
      {
        path: ':id/vendor-risk-assessment',
        lazy: importLazy(() => import('@/modules/agent/components/layout')),
        loader: agentLoader,
      },
      {
        index: true,
        lazy: importLazy(() => import('@/modules/agent/components')),
      },
    ],
  },
  {
    path: `${ROUTES.AGENT}/${ROUTES.SUCCESS}/:id`,
    lazy: importLazy(() => import('@/modules/agent/components/success')),
  },
];

import requestConfig from '@/shared/config/request';
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { getSessionJWT } from '../stytch';
import posthog from 'posthog-js'; // Add this import

enum Methods {
  POST = 'POST',
  PUT = 'PUT',
  GET = 'GET',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

const axiosClient = axios.create({
  baseURL: requestConfig.baseUrl,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

interface CommonProps<D = any> {
  url: string;
  config?: AxiosRequestConfig<D>;
  useCookies?: boolean;
  isAuthRequired?: boolean;
}

export const makeRequest = async <T, D = any>({
  url,
  method,
  config,
  useCookies = false,
  isAuthRequired = true,
  retryCount = 0,
}: CommonProps<D> & {
  method: Methods;
  retryCount?: number;
}): Promise<T> => {
  if (retryCount >= 3) {
    throw new Error('Max retry attempts reached');
  }

  let token: string | undefined;

  if (isAuthRequired) {
    token = await getSessionJWT();
  }
  const headers = isAuthRequired
    ? { ...config?.headers, Authorization: token, token }
    : config?.headers;

  let response: AxiosResponse<T> = {} as AxiosResponse<T>;

  try {
    response = await axiosClient.request<T>({
      url,
      method,
      ...(useCookies && { withCredentials: true }),
      ...config,
      headers,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      posthog.capture('API_ERROR', {
        url,
        method,
        status: error.response?.status,
        message: error.message,
      });
    }

    if (
      axios.isAxiosError(error) &&
      error.response?.status === 401 &&
      isAuthRequired
    ) {
      return makeRequest({
        url,
        method,
        config,
        useCookies,
        isAuthRequired,
        retryCount: retryCount + 1,
      });
    }
    throw error as Error;
  }

  return response.data;
};

export const get = async <T>({
  url,
  config,
  useCookies = false,
  isAuthRequired = true,
}: CommonProps): Promise<T> =>
  makeRequest<T>({
    url,
    method: Methods.GET,
    config,
    useCookies,
    isAuthRequired,
  });

export const post = async <D, T>({
  url,
  data,
  config,
  useCookies = false,
  isAuthRequired = true,
}: CommonProps<D> & {
  data: D;
}): Promise<T> =>
  makeRequest<T>({
    url,
    method: Methods.POST,
    config: { data, ...config },
    useCookies,
    isAuthRequired,
  });

export const put = async <D, T>({
  url,
  data,
  config,
  useCookies = false,
  isAuthRequired = true,
}: CommonProps<D> & {
  data: D;
}): Promise<T> =>
  makeRequest<T>({
    url,
    method: Methods.PUT,
    config: { data, ...config },
    useCookies,
    isAuthRequired,
  });

export const del = async <T>({
  url,
  config,
  useCookies = false,
  isAuthRequired = true,
}: CommonProps): Promise<T> =>
  makeRequest<T>({
    url,
    method: Methods.DELETE,
    config,
    useCookies,
    isAuthRequired,
  });

export const patch = async <T>({
  url,
  config,
  useCookies = false,
  isAuthRequired = true,
}: CommonProps): Promise<T> =>
  makeRequest<T>({
    url,
    method: Methods.PATCH,
    config,
    useCookies,
    isAuthRequired,
  });

export const request = {
  get,
  post,
  put,
  del,
  patch,
};
